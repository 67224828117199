body {
  margin: 0;
}
#root {
  height: 100vh;
  width: 100vw;
}

:root {
  --input-bg-color: #f8f9fa !important;
}

.logo {
  max-width: 300px;
}

.main-container {
  display: flex;
  flex-direction: column;
  // center content
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
}

.content-container {
  padding: 1.5rem !important;
  max-width: 600px;
  max-height: 100%;
  overflow-y: auto;
  margin-left: 1rem;
  margin-right: 1rem;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--text-primary);
  &:hover, &:focus, &:active {
    background-color: var(--primary-color-darker) !important;
    border-color: var(--primary-color-darker) !important;
    color: var(--text-primary) !important;
  }
}

.text-primary {
  color: var(--primary-color) !important;
}

a.link-primary {
  cursor: pointer;
  color: var(--primary-color) !important;
  text-decoration: none;
  &:hover {
    color: var(--primary-color-darker) !important;
    text-decoration: underline;
    text-decoration-color: var(--primary-color-darker) !important;
  }
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--text-secondary);
  &:hover, &:focus, &:active {
    background-color: var(--secondary-color-darker) !important;
    border-color: var(--secondary-color-darker) !important;
    color: var(--text-secondary) !important;
  }
}

.nav-button {
  width: 100%;
}

textarea:focus, input:focus {
  border-color: var(--primary-color-lighter) !important;
  box-shadow: 0 0 0 0.2rem var(--primary-color-lighter) !important;
}

select:focus {
  border-color: var(--primary-color-lighter) !important;
  box-shadow: 0 0 0 0.2rem var(--primary-color-lighter) !important;
}

.react-select__input-container, .react-select-clearable__input-container {
  display: flex;
  justify-content: center;
  input {
    box-shadow: none !important;
  }
}

// compensate centering for clear & dropdown indicators
.react-select-clearable__value-container {
  padding-left: 73px !important;
}

.react-select__control, .react-select-clearable__control {
  border-color: var(--bs-border-color) !important;
}

.react-select__control--is-focused, .react-select-clearable__control--is-focused {
  border-color: var(--bs-border-color) !important;
  box-shadow: 0 0 0 0.2rem var(--primary-color-lighter) !important;
}

.react-select__option--is-focused, .react-select-clearable__option--is-focused {
  background-color: var(--primary-color-lighter) !important;
}

.react-select__option--is-selected, .react-select-clearable__option--is-selected {
  background-color: var(--primary-color) !important;
}


.btn-primary:disabled {
  border-color: var(--primary-color-darker) !important;
  background-color: var(--primary-color-darker) !important;
}

.btn-secondary:disabled {
  border-color: var(--secondary-color-darker) !important;
  background-color: var(--secondary-color-darker) !important;
}

.form-check {
  input {
     background-color: var(--input-bg-color);
     border-color: rgb(222, 226, 230);
     border-width: 2px;
  }
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.form-control {
  background-color: var(--input-bg-color) !important;
  transition: all 0.3s ease-in-out;
}

.react-datepicker__header {
  background-color: var(--input-bg-color) !important;
}

.react-select__control, .react-select-clearable__control {
  background-color: var(--input-bg-color) !important;
  border-radius: 0.375rem !important;
}

.language-picker {
  margin-top:10px;
  display: flex;
  align-items: end;
  color: white !important;
  button {
    padding: 0;
    margin-left: 2px;
    margin-right: 2px;
    text-decoration: none; 
    color: white !important;
    // align right
    &:hover {
      text-decoration: underline;
    }
  }
}

// selected .customer-radio label should be bold
.customer-radio {
  // find selected radio
  input:checked + label {
    font-weight: bold;
  }
}

.btn-outline-primary {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  &:hover, &:focus, &:active {
    background-color: var(--primary-color) !important;
    color: white !important;
    border-color: white !important;
  }
}

.btn-outline-secondary {
  color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  &:hover, &:focus, &:active {
    background-color: var(--secondary-color) !important;
    color: white !important;
    border-color: white !important;
  }
}

.service-button, .datepicker-button {
  width: 100%;
  height: 100%;
}

// custom scroll bar color
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.react-datepicker {
  width: 100%;
  .react-datepicker__month-container {
    width: 100%
  }
  .react-datepicker__day-name {
    width: calc(90% / 7);
  }
  .react-datepicker__day {
    width: calc(90% / 7);
    border-radius: 0.25rem;
  }

  .available-date {
    color: var(--primary-color);
    background-color: var(--primary-color-lighter);
  }
  .unavailable-date {
    color: #cccccc;
  }
  .unchecked-date {
    color: var(--primary-color);
  }
  .react-datepicker__day--selected {
    background-color: var(--primary-color);
    color: white;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: transparent;
  }
}

.cursor-pointer {
  cursor: pointer;
}