
.responsive-table {
  tbody {
    tr {
      border: none !important;
      td {
        border: none !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .responsive-table,
  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table td {
    display: block;
    width: 100%;
  }

  .responsive-table tr {
    margin-bottom: 5px;
  }

  .responsive-table td {
    text-align: center;
    position: relative;
  }

  .responsive-table td:before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
  }
}